/* Textedit structure:
     t-textedit (inline-fleX)
       .t-textedit__prefix?
       .t-textedit__field      (grows to fit, inline-flex)
         input                 (grows to fit)
         counter?
         button*
       .t-textedit__suffix?
*/
t-textedit {
  display: inline-flex !important;
  height: calc(var(--grid-height) - 1px);
}
t-textedit .t-textedit__counter {
  display: none;
  margin-top: 2.5px;
  transition: opacity 0.15s;
  line-height: 15px;
  height: 16px;
  font-size: 11px;
  color: #fff;
  background-color: #000000;
  padding: 1px 6px;
  border-radius: 8px;
  text-align: center;
  min-width: 30px;
}
t-textedit:focus-within .t-textedit__counter {
  opacity: 0.45;
  display: block;
}
t-textedit .t-textedit__field {
  background: #fcfbfb;
  border: 1px solid var(--border-color);
  color: #000000;
  border-radius: var(--border-radius);
  display: inline-flex;
  flex: 1 0 0;
}
t-textedit .t-textedit__field button {
  flex: 0 0 16px;
  height: auto;
  margin: 3px 0 0 4px;
}
t-textedit .t-textedit__field button:last-child {
  margin-right: 4px;
}
t-textedit .t-textedit__field input {
  flex: 1 0 0;
  height: 100%;
  position: relative;
  vertical-align: top;
  background: transparent;
  appearance: none;
  padding: 0 5px;
  color: inherit;
  font-size: 12px;
  line-height: 16px;
  width: 0;
}
t-textedit .t-textedit__field input:focus {
  border-color: #52aefe;
}
t-textedit .t-textedit__field input[readonly]:not(:focus) {
  text-overflow: ellipsis;
}
t-textedit .t-textedit__field:has(input[required]) {
  background-color: #fcf8d0;
  border-color: #b3b3b3;
}
t-textedit .t-textedit__field:has(input[required]):focus {
  border-color: #52aefe;
}
t-textedit .t-textedit__field:has(input[readonly]) {
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
  background: url("~@mod-tollium/web/ui/skins/default/bgdisabled.svg") 0 0/48px 48px;
  opacity: 1;
}
t-textedit .t-textedit__field:has(input[readonly]):focus {
  border-color: #b3b3b3;
}
t-textedit:not(.textedit--hiderequiredifdisabled) .t-textedit__field:has(input[required][readonly]) {
  background: #fcf8d0 url("~@mod-tollium/web/ui/skins/default/bgreadonly-disabled.svg") 0 0/48px 48px;
  border-color: #b3b3b3;
  color: rgba(0, 0, 0, 0.5);
}
t-textedit:not(.textedit--hiderequiredifdisabled) .t-textedit__field:has(input[required][readonly]):focus {
  border-color: #52aefe;
}

.t-textedit__prefix,
.t-textedit__suffix {
  margin-top: 5px;
  white-space: nowrap;
}

.t-textedit__prefix {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.t-textedit__suffix {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}

t-textedit input ~ button.icon:not(.todd--disabled):hover {
  background: rgba(0, 0, 0, 0.1);
}

t-textedit input ~ button.icon .button__img {
  margin: 0;
}