@charset "UTF-8";
/****************************************************************************************************************************
 * Normal buttons
   e.g. the OK, CANCEL, UP and DOWN buttons
*/
:where(button) {
  appearance: none;
  background: transparent;
  font: inherit;
}

button {
  height: 23px;
  line-height: 23px;
  padding: 0 5px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 2px;
}

button > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-align: center !important;
  pointer-events: none;
}

button.button--active > span {
  position: relative;
  top: 1px;
  left: 1px;
}

button.todd--disabled.button--active > span,
button.todd--disabled:active > span {
  left: 0;
  top: 0;
}

button.showmenu > span:after {
  display: inline-block;
  content: "▼";
  font-size: 70%;
  pointer-events: none;
  padding-left: 5px;
}

button .button__img {
  margin-top: 3px;
  margin-left: 4px;
  pointer-events: none;
}

button.button--active .button__img {
  left: 1px;
  top: 1px;
  position: relative;
}

/**/
button.todd--disabled {
  cursor: default;
}

button.todd--disabled[aria-pressed=true] span,
button.todd--disabled.button--active span {
  left: 0;
  top: 0;
}

button.icon {
  padding: 0;
}

button.icon .button__img {
  position: relative;
}

/****************************************************************************************************************************
 * Normal buttons
   e.g. "OK", "CANCEL", "UP" and "DOWN"
*/
button {
  border: 1px solid #b3b3b3;
  color: #000000;
  background-image: linear-gradient(to top, #EBEBEB 0%, #FFFFFF 83%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
}

t-buttongroup > button:hover:not([aria-pressed=true]):not(.todd--disabled) {
  background-image: linear-gradient(to bottom, #EBEBEB 0%, #FFFFFF 83%);
}

button[aria-pressed=true] {
  background-color: #308fe2;
}

button.todd--disabled {
  border: 1px solid #c9c9c9;
  color: #c9c9c9;
  background-color: #ffffff;
  background-image: none;
  box-shadow: none;
}

button.todd--disabled img {
  opacity: 0.45;
}

:not(t-textedit) > button.default:not(.todd--disabled) {
  background-image: linear-gradient(to bottom, #0098EE 0%, #0285CF 100%);
  color: #ffffff;
}
:not(t-textedit) > button.default:not(.todd--disabled):hover {
  background-image: linear-gradient(to top, #0098EE 0%, #0285CF 100%);
}

:not(t-toolbar-buttongroup):not(buttongroup):not(t-textedit) > button.default:not(.todd--disabled):hover {
  background-color: #007dc3;
  background-image: linear-gradient(to top, #0098EE 0%, #0285CF 100%);
}

:not(t-textedit) > button.default:not(.todd--disabled).button--active {
  background-image: none;
  background-color: #007ec4;
}

button:focus {
  border-color: #52aefe;
}